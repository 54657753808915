/// Copyright 2024 Hitachi Energy. All rights reserved.

import React from 'react';
import { getRTUData } from '../../../service/assetInventoryService';
import AssetTable from '../../../Components/Common/AssetTable';
import { RTUColDefs } from '../../../const/AssetColumnHeaders';
import { ProductType } from '../../../const/appConstants';

const RTUTable = () => {
  const customerGuid = 'G12024019';
  return (
    <AssetTable
      name={ProductType.RTU}
      columnDefs={RTUColDefs}
      customerGuid={customerGuid}
      getTableData={getRTUData}
    />
  );
};

export default RTUTable;
