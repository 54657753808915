/// Copyright 2024 Hitachi Energy. All rights reserved.

import React from 'react';
import { getMicroSCADAData } from '../../../service/assetInventoryService';
import AssetTable from '../../../Components/Common/AssetTable';
import { MicroscadaColDef } from '../../../const/AssetColumnHeaders';
import { ProductType } from '../../../const/appConstants';

const MicroSCADATable = () => {
  const customerGuid = 'G06361591';
  return (
    <AssetTable
      name={ProductType.MicroSCADA}
      columnDefs={MicroscadaColDef}
      customerGuid={customerGuid}
      getTableData={getMicroSCADAData}
    />
  );
};

export default MicroSCADATable;
