/// Copyright 2024 Hitachi Energy. All rights reserved.

import React, { useCallback, useEffect, useState } from 'react';
import {
  HvEmptyState,
  HvGrid,
  HvLoading,
  HvTable,
  HvTableBody,
  HvTableCell,
  HvTableContainer,
  HvTableHead,
  HvTableHeader,
  HvTableRow
} from '@hitachivantara/uikit-react-core';
import Pagination from './Pagination';
import { StatusCode, TableRowCount } from '../../const/appConstants';
import { Ban } from '@hitachivantara/uikit-react-icons';
import './AssetTable.scss';

interface ColumnDefinitionType {
  headerText: string;
  accessor: string;
  cellType?: string;
  fixed?: string;
}

interface ApiResponseItemType {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  items: AssetTableDataType[];
  pageIndex: number;
  totalAssetsCount: number;
  totalPages: number;
}

interface ApiResponseDataType {
  data: ApiResponseItemType;
  message: string;
  statusCode: number;
  success: true;
}

interface ApiResponseType {
  data: ApiResponseDataType;
  status: StatusCode;
}

interface AssetTableProps {
  columnDefs: ColumnDefinitionType[];
  name: string;
  customerGuid: string;
  getTableData: (
    guid: string,
    pageIndex: number,
    pageSize: number,
    column: string,
    sortOrder: string,
    searchText: string
  ) => Promise<ApiResponseType>;
}

interface RTUDataType {
  productSn: string | null;
  siteId: string | null;
  productSeries: string | null;
  productFamily: string | null;
  productVersion: string | null;
  productName: string | null;
  productFirmware: string | null;
  productLifeCycle: string | null;
  productDefinition: string | null;
  siteOperatorGuid: string | null;
}

interface IEDDataType {
  productSn: string | null;
  siteId: string | null;
  productSeries: string | null;
  productFamily: string | null;
  productVersion: string | null;
  productName: string | null;
  productFirmware: string | null;
  productLifeCycle: string | null;
  productDefinition: string | null;
  siteOperatorGuid: string | null;
}

interface MicroSCADADataType {
  systemId: string | null;
  siteId: string | null;
  computerId: string | null;
  computerName: string | null;
  operatingSystemName: string | null;
  ipAddress: string | null;
  productSeries: string | null;
  productName: string | null;
  productVersion: string | null;
  productLifeCycle: string | null;
  siteOperatorGuid: string | null;
}

type AssetTableDataType = MicroSCADADataType | IEDDataType | RTUDataType;

// props is only used for storybook purposes
const AssetTable = (props: AssetTableProps) => {
  const { name, columnDefs, customerGuid, getTableData } = props;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<AssetTableDataType[]>();
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const pageSize = TableRowCount.DefaultTableRowCount;

  const fetchAssetData = useCallback(async () => {
    setIsLoading(true);
    const response = await getTableData(
      customerGuid,
      page + 1,
      pageSize,
      '',
      '',
      ''
    );
    if (response) {
      const responseData = response?.data?.data?.items;
      setData(responseData);
      setDataCount(response?.data?.data?.totalAssetsCount);
    }
    setIsLoading(false);
  }, [customerGuid, getTableData, page, pageSize]);

  const onTablePageChange = useCallback(
    (value: number) => {
      setPage(value);
      if (value !== page) {
        setData([]);
      }
    },
    [page]
  );

  useEffect(() => {
    fetchAssetData();
  }, [fetchAssetData]);

  return (
    <HvGrid>
      <HvTableContainer
        data-testid='asset-table-container'
        className='asset-table-wrapper'
      >
        <HvTable
          data-testid='asset-table'
          id='asset-table'
        >
          <HvTableHead>
            <HvTableRow
              style={{ justifyContent: 'center', backgroundColor: 'red' }}
            >
              {columnDefs.map((el: ColumnDefinitionType) => (
                <HvTableHeader
                  key={el.headerText}
                  className='asset-table-header'
                >
                  {el.headerText}
                </HvTableHeader>
              ))}
            </HvTableRow>
          </HvTableHead>
          <HvTableBody>
            {isLoading && (
              <HvTableRow
                key='Loading'
                hover
                striped
              >
                <HvTableCell colSpan={8}>
                  <HvLoading
                    id='asset-table-data-loading'
                    data-testid='asset-table-data-loading'
                    small
                    label={`Fetching ${name} Data`}
                  />
                </HvTableCell>
              </HvTableRow>
            )}
            {data &&
              data.length > 0 &&
              !isLoading &&
              data.map((el: any) => (
                <HvTableRow
                  key={el.siteId}
                  hover
                  striped
                >
                  {columnDefs.map((col: ColumnDefinitionType) => {
                    return (
                      <HvTableCell key={col.headerText}>
                        {el[col.accessor]}
                      </HvTableCell>
                    );
                  })}
                </HvTableRow>
              ))}
            {!isLoading && (data === undefined || data?.length === 0) && (
              <HvTableRow>
                <HvTableCell colSpan={100}>
                  <HvEmptyState
                    message='No data to display'
                    icon={<Ban />}
                  />
                </HvTableCell>
              </HvTableRow>
            )}
          </HvTableBody>
        </HvTable>
        <Pagination
          page={page}
          pageSize={pageSize}
          onPageChange={onTablePageChange}
          dataCount={dataCount}
        ></Pagination>
      </HvTableContainer>
    </HvGrid>
  );
};

export default AssetTable;
