/// Copyright 2024 Hitachi Energy. All rights reserved.

import React from 'react';
import { getIEDData } from '../../../service/assetInventoryService';
import AssetTable from '../../../Components/Common/AssetTable';
import { IEDColDefs } from '../../../const/AssetColumnHeaders';
import { ProductType } from '../../../const/appConstants';

const IEDTable = () => {
  const customerGuid = 'G01578290';
  return (
    <AssetTable
      name={ProductType.IED}
      columnDefs={IEDColDefs}
      customerGuid={customerGuid}
      getTableData={getIEDData}
    />
  );
};

export default IEDTable;
