/// Copyright 2024 Hitachi Energy. All rights reserved.

export const MicroscadaColDef = [
  {
    headerText: 'System ID',
    accessor: 'systemId',
    cellType: 'alpha-numeric',
    fixed: 'left'
  },
  {
    headerText: 'Computer Name',
    accessor: 'computerName',
    cellType: 'alpha-numeric'
  },
  {
    headerText: 'Product Series',
    accessor: 'productSeries',
    style: { textTransform: 'capitalize' },
    cellType: 'alpha-numeric'
  },
  {
    headerText: 'Product Name',
    accessor: 'productName',
    cellType: 'numeric'
  },
  {
    headerText: 'Product Version',
    accessor: 'productVersion',
    style: { textTransform: 'capitalize' },
    cellType: 'alpha-numeric',
    sortable: false
  },
  {
    headerText: 'IP Address',
    accessor: 'ipAddress',
    style: { textTransform: 'capitalize' },
    cellType: 'alpha-numeric'
  },
  {
    headerText: 'Operating System',
    accessor: 'operatingSystemName',
    cellType: 'link',
    fixed: 'right',
    sortable: false
  },
  {
    headerText: 'Product Lifecycle',
    accessor: 'productLifeCycle',
    cellType: 'link',
    fixed: 'right',
    sortable: false
  }
];

export const IEDColDefs = [
  {
    headerText: 'Product Serial No',
    accessor: 'productSn',
    cellType: 'alpha-numeric'
  },
  {
    headerText: 'Product Series',
    accessor: 'productSeries',
    cellType: 'alpha-numeric'
  },
  {
    headerText: 'Product Family',
    accessor: 'productFamily',
    cellType: 'alpha-numeric'
  },
  {
    headerText: 'Product Version',
    accessor: 'productVersion',
    cellType: 'alpha-numeric'
  },
  {
    headerText: 'Product Name',
    accessor: 'productName',
    cellType: 'alpha-numeric'
  },
  {
    headerText: 'Product Firmware',
    accessor: 'productFirmware',
    cellType: 'alpha-numeric'
  },
  {
    headerText: 'Product Lifecycle',
    accessor: 'productLifeCycle',
    cellType: 'alpha-numericnk'
  },
  {
    headerText: 'Product Definition',
    accessor: 'productDefinition',
    cellType: 'alpha-numeric'
  }
];

export const RTUColDefs = [
  {
    headerText: 'Product Serial No',
    accessor: 'productSn',
    fixed: 'left'
  },
  {
    headerText: 'Product Series',
    accessor: 'productSeries'
  },
  {
    headerText: 'Product Name',
    accessor: 'productName',
    cellType: 'numeric'
  },
  {
    headerText: 'Article Number',
    accessor: 'erpArticleNo'
  },
  {
    headerText: 'Product Lifecycle',
    accessor: 'productLifeCycle'
  },
  {
    headerText: 'Product Firmware',
    accessor: 'productFirmware',
    cellType: 'productFirmware'
  }
];
